<template>
  <div class="contacts row Summary tabCont">
    <div class="text hiden col-5">
      <h2>便捷记录每一条纪要</h2>
      <p>
        在会上讨论的重要内容，在办公室想到的idea，所有需要记事本来记录的WOLB都能轻松解决，告别毫无次序，让记事变得井井有条。
      </p>
      <p>
        同时事件产生纪要，纪要追溯事件，让纪要和事件无缝衔接。
      </p>
    </div>
    <div class="img col-4">
      <img src="../../assets/imgs/product/summary_phone_img.png" alt="" />
    </div>

    <div class="text show col-5">
      <h2>便捷记录每一条纪要</h2>
      <p>
        在会上讨论的重要内容，在办公室想到的idea，<br />所有需要记事本来记录的WOLB都能轻松解决，<br />告别毫无次序，让记事变得井井有条。<br />
        同时事件产生纪要，纪要追溯事件，<br />让纪要和事件无缝衔接。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Summary",
};
</script>
<style lang="css">
@import "./style.css";
</style>
