<template>
  <div class="main">
    <Nav />
    <div class="mainCont">
      <router-view />
    </div>
    <PageFooter :footerColor="footerColor" />
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import Nav from "./components/TopNav";
import PageFooter from "./components/PageFooter";
import img1 from "@/assets/imgs/product/product_banner_img.gif";
import img2 from "@/assets/imgs/product_img.png";
import img3 from "@/assets/imgs/introduction_img.png";
import img4 from "@/assets/imgs/team_img.png";
import img5 from "@/assets/imgs/cooperation_img.png";
export default {
  components: { Nav, PageFooter },
  setup() {
    const footerColor = ref("#fff");
    const loadImages = () => {
      let imgs = [img1, img2, img3, img4, img5];

      for (let img of imgs) {
        let image = new Image();
        image.src = img;
      }
    };

    onMounted(() => {
      loadImages();
    });
    return {
      footerColor,
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.path === "/") {
        console.log(window.screen.width);
        if (window.screen.width > 600) {
          this.footerColor = "#F9FAFF";
        } else {
          this.footerColor = "#EEF0F3";
        }
      } else {
        this.footerColor = "#FFF";
      }
      $(".main").scrollTop(0);
    },
  },
  mounted() {
    document.dispatchEvent(new Event("custom-render-tigger"));
  },
};
</script>
<style lang="scss">
.mainCont {
  min-height: 82.5%;
  overflow: hidden;
}
.showBlock,
.show {
  display: none !important;
}
.navDropdown {
  min-width: 100px;
  text-align: center;
  margin-top: -25px !important;
  .n-dropdown-option-body__label{
    font-size: 16px;
    // margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .hiden {
    display: none !important;
  }
  .show {
    display: inline-block !important;
  }
  .showBlock {
    display: block !important;
  }
  .mainCont {
    min-height: auto;
  }
}
</style>
