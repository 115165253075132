import "./assets/js/rem";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import { ActionSheet } from "vant";
const app = createApp(App);
import naive from 'naive-ui'
// const naive = create({
//     components: [nDropdown]
//   })
app.use(naive);
app.use(router);
app.use(ActionSheet);
app.mount("#app");
