<template>
  <div class="contacts row tabCont">
    <div class="text col-5 hiden">
      <h2>有效记录人脉资源</h2>
      <p>
        对自己人生有更高追求时一定需要对自身人脉资源进行管理。在管理每个人的要点信息后可随时维护并加强联系，逐步对人脉以群组和标签进行分层管理不同圈层的人脉，来自圈层的助力才能让自己变得更优秀，体现出更大的价值！
      </p>
    </div>
    <div class="img col-3">
      <img src="../../assets/imgs/product/contacts_phone.png" alt="" />
    </div>
    <div class="text col-5 show">
      <h2>有效记录人脉资源</h2>
      <p>
        对自己人生有更高追求时一定需要对自身人脉资源进行管理。在管理每个人的要点信息后可随时维护并加强联系，逐步对人脉以群组和标签进行分层管理不同圈层的人脉，来自圈层的助力才能让自己变得更优秀，体现出更大的价值！
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Connection",
};
</script>
<style lang="css">
@import "./style.css";
</style>
